import React from 'react'
import { Link } from 'react-scroll'
import imgme from '../assets/profile-pic.jpeg'

export default function Aboutme() {
    return (
        <div id='About' className='about-page'>
        <div className='aboutme'>
            <h1>About me</h1>
            <p>Here you will find more information about me, what I do, and my current skills mostly in terms of programming and technology</p>
            </div>
            <div className="row ">
                <div className="col-md-6 get-to-know-me" >
                    <h2>
                    <img className='imgme' src={imgme} alt="" />
                    </h2>
                    {/* <p>I'm a <strong>Frontend Web Developer</strong> building the Front-end of Websites and Web Applications that leads to the success of the overall product. Check out some of my work in the <strong>Projects</strong> section.</p>
                    <p>I also like sharing content related to the stuff that I have learned over the years in
                     <strong> Web Development</strong> so it can help other people of the Dev Community. Feel free to Connect or Follow me on my <a className='p-linkedin' target='_blank' href="https://www.linkedin.com/in/hamzazaka/">Linkedin</a> where I post useful content related to Web Development and Programming</p>
                    <p>I'm open to <strong>Job</strong> opportunities where I can contribute, learn and grow. If you have a good opportunity that matches my skills and experience then don't hesitate to <strong>contact </strong> me.</p> */}
                    <p><strong> Front End Developer</strong> with expertise in developing and implementing front-end solutions for high-profile organizations. Proven track record in software development, adept at contributing to all phases of development cycle, from collaborative design with Figma designers to leading front-end development teams. Skilled in engaging with clients, users, and stakeholders to identify and address issues with new features, fostering effective communication between development and design teams to ensure seamless deployment across multiple stages. <strong> Steady focus on quality, timeliness, and cost control. </strong></p>
                    <Link smooth={true} to='Contact' offset={-110} className='btn btn-contact' href=""><span></span> Contact</Link>

                </div>
                <div className="col-md-6 my-skills">
                    <h2>My Skills</h2>
             
                    <p href="">React</p>
                    <p href="">Redux</p>
                    <p href="">Next.js</p>
                    <p href="">TypeScript</p>
                    <p href="">Angular</p>
                    <p href="">UX/UI</p>
                    <p href="">Tailwind </p>
                    <p href="">Object-Oriented Programming (OOP) </p>
                    <p href="">Figma</p>
                    <p href="">JavaScript</p>
                    <p href="">Bootstrap</p>
                    <p href="">REST API</p>
                    <p href="">Jira</p>
                    <p href="">Material-UI</p>
                    <p href="">HTML</p>
                    <p href="">CSS</p>
                    <p href="">GIT</p>
                    <p href="">Github</p>
                    <p href="">Nodejs</p>
                    <p href="">Microservices</p>
                    <p href="">AWS  </p>
                    <p href="">GraphQL</p>
                    <p href="">SQL</p>
                    <p href="">Python</p>
                    <p href="">Azure</p>
                    <p href="">Webpack</p>
                    <p href="">Jest</p>
                    <p href=""> DevOps</p>
                    <p href="">MongoDB</p>
                    <p href="">StoryBook</p>
                </div>
            </div>
        </div>
    )
}
