import React from 'react'
import covid19 from '../assets/covid-19.png';
import bookstop from '../assets/bookstop-react.png';
import dashboardreact from '../assets/dashboard-react.png';
import portfolio from '../assets/hamza-portfolio.png';
import ccos from '../assets/ccos.png';
import testandgo from '../assets/testandgo.png';
import cesir from '../assets/cesir.png';
import privacy from '../assets/privacy.png';
import social from '../assets/social.png';
export default function Projects() {
    return (
        <div id='Projects' className='project-page'>
            <div className='aboutme'>
            <h1>Projects</h1>
            <p>Here you will find some of the projects that I created with each project containing it's github and live access</p>
            </div>
            <div className="row">
            <div className="col-lg-6 project-img">
                <img src={ccos} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>CCOS - Coordination, Communication, and Operations Support</h1>
                <p>The CTSA Program is designed to develop innovative solutions that will improve the efficiency, quality and impact of the process for turning observations in the laboratory, clinic and community into interventions that improve the health of individuals and the public</p>
                 <p><strong>Technologies :</strong> React, Next Js, Material UI, JavaScript, CSS, Bootstrap, JSON, REST APIS, Redux, Node Js</p>
                <a rel="noreferrer noopener" target='_blank' href="https://ccos-cc.ctsa.io" className='btn btn-contact'>Live </a>
            </div>

        </div>
        <div className="row">
            <div className="col-lg-6 project-img">
                <img src={testandgo} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>Testandgo</h1>
                <p>Where Care Meets Convenience, Health tests and products are available to you day or night at our testandgo™ kiosk. testandgo™ kiosks is a highly integrated, one-stop-destination for comprehensive, whole health solutions</p>
                 <p><strong>Technologies :</strong> React, Chart.js, JavaScript, CSS, Material UI, JSON, REST APIS, Yarn, Node, Github</p>
                <a rel="noreferrer noopener" target='_blank' href="https://www.testandgo.com/" className='btn btn-contact'>Live </a>
            </div>

        </div>
        <div className="row">
            <div className="col-lg-6 project-img">
                <img src={cesir} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>Centers of Excellence for Influenza Research and Response (CEIRR)</h1>
                <p>The Centers of Excellence for Influenza Research and Response (CEIRR) Network is a multidisciplinary and collaborative research network funded by the National Institute of Allergy and Infectious Diseases (NIAID) to study the natural history, transmission, and pathogenesis of influenza and provide an international research infrastructure to address influenza outbreaks.</p>
                 <p><strong>Technologies :</strong> React, Chart.js, JavaScript, CSS, Sass, Material UI, Node, REST APIS, Yarn</p>
                <a rel="noreferrer noopener" target='_blank' href="https://www.ceirr-network.org/" className='btn btn-contact'>Live </a>
            </div>

        </div>
        <div className="row">
            <div className="col-lg-6 project-img">
                <img src={dashboardreact} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>Admin DashBoard</h1>
                <p>Admin React Dashboard coded in <strong>React</strong>. It comes packed with a set of beautiful elements and components built by just Bootstrap, React and Chart.js. It has a fully responsive layout, multiple color schemes for both Bootstrap elements and layout components. Admin React Dashboard is powered by Bootstrap 4, Webpack and it uses create-react-app. <strong>I am working on it make it mobile responsive</strong></p>
                 <p><strong>Technologies :</strong> React, Chart.js, JavaScript, CSS, Bootstrap, Material UI, JSON, REST APIS, Yarn</p>
                <a rel="noreferrer noopener" target='_blank' href="https://admin-dashboard-2aef9.web.app/" className='btn btn-contact'>Live </a>
            </div>

        </div>
        <div className="row">
            <div className="col-lg-6 project-img">
                <img src={privacy} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>Privacy OS</h1>
                <p>Privacy OS mission is to transform the AI landscape by seamlessly integrating Privacy by Design and AI governance into data systems, enabling businesses and consumers to harness the power of AI while safeguarding privacy. We are committed to simplifying AI adoption, especially for startups with limited resources, and ensuring ethical AI practices.</p>
                 <p><strong>Technologies :</strong> HTML, JavaScript, Sass, Bootstrap, JSON, Node Js</p>
                <a rel="noreferrer noopener" target='_blank' href="https://privacyos.ai" className='btn btn-contact'>Live </a>
            </div>

        </div>
            <div className="row">
            <div className="col-lg-6 project-img">
                <img src={social} alt="project" />
            </div>
            <div className="col-lg-6 project-description pd-2">
                <h1>Social Magic</h1>
                <p>AI Content Engine for your Brand, Its an AI Platform for your all social media needs. Generate Posts for All social media with just one prompt, Its AI powered Engine generate content for brand specifically to make the viral posts on social media, Get 7X Engagement on the Post faster than ever, 10x Saving time and Ease. Create Brand Specific Post in seconds, Let your brand stand out</p>
                 <p><strong>Technologies :</strong> HTML, JavaScript, CSS, Bootstrap, JSON, REST APIS</p>
                <a rel="noreferrer noopener" target='_blank' href="https://socialmagic.ai" className='btn btn-contact'>Live </a>
            </div>

        </div>
            <div className="row">
                <div className="col-lg-6 project-img">
                    <img src={covid19} alt="project" />
                </div>
                <div className="col-lg-6 project-description">
                    <h1>Covid-19 Tracker</h1>
                    <p>Covid-19 is a <strong>React project</strong> developed in support of people suffering and affected due to Covid-19 virus all around the world. The app takes a very simple and light weight design keeping in mind proper responsiveness throughout different platforms like web and mobile responsiveness. The app lets the user track the growth of active cases in their country and provides statistics from all around the world.</p>
                     <p><strong>Technologies :</strong> React, Chart.js, JavaScript, CSS, Bootstrap, Material UI, JSON, REST APIS</p>
                    <a rel="noreferrer noopener" target='_blank' href="https://covid-19-tracker-887.web.app/" className='btn btn-contact'>Live </a>
                </div>

            </div>


              

            <div className="row">
                <div className="col-lg-6 project-img">
                    <img src={portfolio} alt="project" />
                </div>
                <div className="col-lg-6 project-description pd-2">
                    <h1>Hamza Portfolio</h1>
                    <p>My Portfolio which you are looking at right now is build by using <strong>React</strong>. It has fully responsive layout built by using latest version of Bootstrap both compatible with mobile and web. The single page application using different technologies to making it fully responsive and impress the recruiters. </p>
                     <p><strong>Technologies :</strong> React, JavaScript, CSS, Bootstrap, NPM packages</p>
                    <a rel="noreferrer noopener" target='_blank' href="/" className='btn btn-contact'>Live </a>
                </div>

            </div>

            <div className="row">
                <div className="col-lg-6 project-img">
                    <img src={bookstop} alt="project" />
                </div>
                <div className="col-lg-6 project-description pd-2">
                    <h1>BookStop</h1>
                    <p>BookStop is a React app that works with the BookStop API server-side. The app allows a user to log in, track books they have already read and books they want to read in two separate lists, and search for new reads using the Google API.Build with a team working in an agile Environment</p>
                     <p><strong>Technologies :</strong> React, JavaScript, CSS, Bootstrap, Node.js, MERN, Yarn, Canva </p>
                    <a rel="noreferrer noopener" target='_blank' href="https://bookstop-books.netlify.app/" className='btn btn-contact'>Live </a>
                </div>

            </div>
             
        </div>
    )
}
